<template>
    <v-container>
		
		<v-text-field
            label="Filtruj"
            single-line
            hide-details
			@input="setClientSearch"
			:value="getClientsSearch"
            class="mb-3"
        ></v-text-field>
        <v-data-table
			:loading="!isDownloaded"
			:search="getClientsSearch"
            :headers="headers"
            :items="clients"
            item-key="id"
            class="elevation-2"
            :hide-default-footer="true"
            :disable-pagination="true"
            :sort-desc="false"
        >
            <template v-slot:item.type="{ item }">

                {{item.type === 0 ? "Firma" : "Osoba Fizyczna"}}

            </template>
            <template v-slot:item.address="{ item }">

                {{ `${item.postcode ? item.postcode : ''} ${item.city ? item.city+',' : '' } ${item.street ? item.street : ''}` }}
                <!-- {{ item.type === 1 ? item.nip : `${item.postcode ? item.postcode : ''} ${item.city ? item.city+',' : '' } ${item.street ? item.street : ''}` }} -->

            </template>
            <template v-slot:item.date="{ item }">

                    {{item.date.split('T')[0]}}

            </template>
            <template v-slot:item.actions="{ item }">
				<div class="d-flex align-center">
					<RemoveClinet :clientId='item._id' :key="item._id"/>
					<EditClient :client='item' :key="item._id+'2'" />
                    <v-btn
                        color="indigo"
                        dark
                        medium
                        :to="`client/${item._id}`"
                        class="mt-1 mb-1 mr-1 white--text"
                        fab
                        small
                    >
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
				</div>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import io from 'socket.io-client';
import EditClient from '@/components/molecules/EditClient.vue';
import RemoveClinet from '@/components/molecules/RemoveClient.vue';

const token = localStorage.getItem('user');

export default {
    components: {
		EditClient,
		RemoveClinet
	},
	computed: {
		getClientsSearch(){
            return this.$store.getters.getClientsSearch;
        }
	},
    data(){
        return{
			clients: [],
			socket: io(this.url),
			isDownloaded: false,
            headers: [
                { text: 'Typ', value: 'type', align: 'left', sortable: true },
                { text: 'Nazwa', value: 'name', sortable: true },
                { text: 'Imię', value: 'firstName', sortable: true },
                { text: 'Nazwisko', value: 'lastName', sortable: true },
                { text: 'Stanowisko', value: 'position', sortable: true },
                { text: 'Dodano', value: 'date', sortable: true },
                { text: 'NIP', value: 'nip', sortable: true },
                { text: 'Adres', value: 'address', sortable: true },
                { text: 'Email', value: 'email', sortable: true },
                { text: 'Akcje', value: 'actions', sortable: false },
            ]
        }
	},
    methods:{
		setClientSearch(text){
			this.$store.commit('updateFilterClientsSearch', text);
		},
        getClients(){
            this.$axios.get(this.url + 'api/clients', {
                headers: { 'x-access-token': token }
            }).then(resp => {
				this.clients = resp.data
				this.isDownloaded = true;
            })
		}
	},
    created(){
        this.getClients();

        this.socket.on('newClientAdded', () => {
            this.getClients()
        });

        this.socket.on('clientDeleted', () => {
            this.getClients()
        });
        this.socket.on('clientUpdated', () => {
            this.getClients()
        });
    },
}
</script>
