<template>
    <v-container class="popup">
        <div class="my-4 text-left">
            <v-btn
                color="indigo"
                dark
                medium
                @click="popup = !popup"
            >
                Dodaj klienta
            </v-btn>
    </div>
        <v-dialog
            v-model="popup"
            max-width="500px"
        >
            <v-card>
                <v-form ref="form">
                    <v-card-title>
                        Dodaj klienta
                    </v-card-title>
                    <v-card-text>
                        <v-radio-group v-model="isFirm" :mandatory="false">
                            <v-radio label="Firma" :value="true"></v-radio>
                            <v-radio label="Osoba fizyczna" :value="false"></v-radio>
                        </v-radio-group>
                        <v-text-field
                            v-model="firstName"
                            label="Imię"
                            :error-messages="firstNameErrors"
                            clearable
                            @input="$v.firstName.$touch()"
                            @blur="$v.firstName.$touch()"
                        ></v-text-field>
                        <v-text-field
                            v-model="lastName"
                            label="Nazwisko"
                            :error-messages="lastNameErrors"
                            clearable
                            @input="$v.lastName.$touch()"
                            @blur="$v.lastName.$touch()"
                        ></v-text-field>
                        <v-text-field
                            v-if="isFirm"
                            v-model="position"
                            label="Stanowisko"
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-if="isFirm"
                            v-model="name"
                            label="Nazwa"
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-if="isFirm"
                            v-model="nip"
                            label="NIP"
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-model="postcode"
                            label="Kod pocztowy"
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-model="city"
                            label="Miejscowość"
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-model="street"
                            label="Ulica"
                            clearable
                        ></v-text-field>
                        <!-- <v-text-field
                            v-model="address"
                            label="Adres"
                            clearable
                            v-if="!isFirm"
                        ></v-text-field> -->
                        <v-text-field
                            v-model="email"
                            label="Email"
                            clearable
                            :error-messages="emailErrors"
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()"           
                        ></v-text-field>
                    </v-card-text>
                    <p class="font-weight-regular pl-6" v-if="feedback">{{ feedback }}</p>
                    <v-card-actions class="ml-4">
                        <v-btn
                            color="primary"
                            @click="addClient"
                            :disabled="$v.$invalid"
							:loading="isLoaded"
                        >
                            Dodaj
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
const { required, email } = require('vuelidate/lib/validators')
import { errorMsg } from '@/mixins/Validation';

const token = localStorage.getItem('user');

export default{
    name: 'Popup',

    data(){
        return{
            isFirm: false,
            popup: false,
            feedback: null,
            name: null,
            email: null,
            nip: null,
            postcode: null,
            city: null,
            street: null,
			type: null,
			isLoaded: false,
            address: null,
            position: null,
            lastName: null,
            firstName: null,
        }
    },
    mixins: [validationMixin, errorMsg],
    validations: {
        // name: {
        //     required,
        // },
        lastName: {
            required
        },
        firstName: {
            required
        },
        email: {
            required,
            email
        },
    },
    methods:{
		resetValidation(){
			this.$refs.form.reset()
			this.$nextTick(() => { this.$v.$reset() })
		},
        async addClient(){
            // if(!this.$v.$invalid){
			// 	this.isLoaded = true;
            //     this.$axios.post(`${this.url}api/clients`, {
            //         type: this.isFirm ? 0 : 1,
            //         name: this.name,
            //         firstName: this.firstName,
            //         lastName: this.lastName,
            //         position: this.position,
            //         email: this.email,
            //         nip: this.nip,
            //         city: this.city,
            //         postcode: this.postcode,
            //         street: this.street,
            //     }, { headers: { 'x-access-token': token } }).then(() => {
			// 		this.masterAgreement()
            //         this.resetValidation()
			// 		this.popup = false;
			// 		this.isLoaded = false;
                    
            //         // this.isFirm ? this.masterAgreementFirm() : this.masterAgreementPerson() 
            //     })
            // }

            try {

                this.isLoaded = true;

                const agreementNumber = await this.getNextAgreementNumber(); // Pobierz numer umowy
                
                // Jeśli agreementNumber jest nullem lub undefined, wstrzymaj tworzenie klienta
                if (!agreementNumber) {
                    throw new Error('Unable to get agreement number');
                }

                const newClientResponse = await this.$axios.post(`${this.url}api/clients`, {
                    type: this.isFirm ? 0 : 1,
                    name: this.name,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    position: this.position,
                    email: this.email,
                    nip: this.nip,
                    city: this.city,
                    postcode: this.postcode,
                    street: this.street,
                    agreement: agreementNumber,
                }, { headers: { 'x-access-token': token } });

                if (newClientResponse.status === 201) {
                    this.masterAgreement(agreementNumber);
                    this.resetValidation();
                    this.popup = false;
                    this.isLoaded = false;
                } else {
                    // Obsłuż inne statusy HTTP lub błędy
                }
            } catch (error) {
                console.error('Error adding client:', error);
            } finally {
                this.isLoaded = false;
            }
        },
        async getNextAgreementNumber() {
            try {
                const response = await this.$axios.get(`${this.url}api/agreement-number/next`, { headers: { 'x-access-token': token }});
                return response.data.agreementNumber; 
            } catch (error) {
                console.error('Error getting the next agreement number:', error);
            }
        },
        async masterAgreement(agreementNumber) {
            try {
                console.log("WYSYLANIE")
                await this.$axios.post(`https://hook.eu2.make.com/oo8aot0xu99tr236or383trvhj2x42ci`, {
                    type: this.isFirm ? 'company' : 'natural',
                    name: this.name,
                    street: this.street,
                    postcode: this.postcode,
                    city: this.city,
                    nip: this.nip,
                    email: this.email,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    position: this.position,
                    number: agreementNumber,
                }, { headers: { 'x-access-token': token } }).then((res) => { console.log(res); })
            } catch (error) {
                console.log(error);
            }
        },
        // async masterAgreementFirm() {
        //     try {
        //         await this.$axios.post(`https://hook.eu2.make.com/hte54l6a2muo1ftthrme23xldf115ru1`, {
        //             type: 'company',
        //             name: this.name,
        //             street: this.street,
        //             postcode: this.postcode,
        //             city: this.city,
        //             nip: this.nip,
        //             email: this.email,
        //             firstName: this.firstName,
        //             lastName: this.lastName,
        //             position: this.position,
        //         }, { headers: { 'x-access-token': token } }).then((res) => { console.log(res); })
        //     } catch (error) {
        //         console.log(error);
        //     }
        // },
        // async masterAgreementPerson() {
        //     try {
        //         await this.$axios.post(`https://hook.eu2.make.com/hte54l6a2muo1ftthrme23xldf115ru1`, {
        //             type: 'normal',
        //             name: this.name,
        //             address: this.address,
        //             email: this.email,
        //         }, { headers: { 'x-access-token': token } }).then((res) => { console.log(res); }) 
        //     } catch (error) {
        //         console.log(error);
        //     }
        // },
    },
}
</script>
