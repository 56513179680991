<template>
    <div class="popup">
        <div class="my-4">
            <v-btn
                color="blue"
                dark
                medium
                class="mt-1 mb-1 mr-1 white--text"
				fab
				small
                @click="popup = !popup"
            >
                <v-icon dark>mdi-pencil</v-icon>
                
            </v-btn>
        </div>
        <v-dialog
            v-model="popup"
            max-width="500px"
        >
            <v-card>
                <v-form ref="form" >
                    <v-card-title>
                        Edytuj klienta
                    </v-card-title>
                    <v-card-text>
                        <v-radio-group v-model="isFirm" :mandatory="false">
                            <v-radio label="Firma" :value="true"></v-radio>
                            <v-radio label="Osoba fizyczna" :value="false"></v-radio>
                        </v-radio-group>
                        <v-text-field
                            v-if="isFirm"
                            v-model="name"
                            label="Nazwa"
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-model="firstName"
                            label="Imię"
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-model="lastName"
                            label="Nazwisko"
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-model="position"
                            label="Stanowisko"
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-if="isFirm"
                            v-model="nip"
                            label="NIP"
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-model="postcode"
                            label="Kod pocztowy"
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-model="city"
                            label="Miejscowość"
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-model="street"
                            label="Ulica"
                            clearable
                        ></v-text-field>
                        <v-text-field
                            v-model="email"
                            label="Email"
                            clearable
                            :error-messages="emailErrors"
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()"
                        ></v-text-field>
                    </v-card-text>
                    <p class="font-weight-regular pl-6" v-if="feedback">{{ feedback }}</p>
                    <v-card-actions class="ml-4">
                        <v-btn
                            color="primary"
                            @click="updateClient"
                            :disabled="$v.$invalid"
							:loading="isLoaded"
                        >
                            Edytuj
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
const { required, email } = require('vuelidate/lib/validators')
import { errorMsg } from '@/mixins/Validation';

const token = localStorage.getItem('user');

export default{
    name: 'Popup',
    props: {
        client: {
            type: Object
        }
    },
    data(){
        return{
            isFirm: this.client.type === 0 ? true : false,
            popup: false,
            feedback: null,
            name: this.client.name,
            firstName: this.client.firstName,
            lastName: this.client.lastName,
            email: this.client.email,
            nip: this.client.nip,
            postcode: this.client.postcode,
            position: this.client.position,
            city: this.client.city,
            street: this.client.street,
            type: this.isFirm ? 0 : 1,
			id:  this.client._id,
			isLoaded: false,
        }
    },
    mixins: [validationMixin, errorMsg],
    validations: {
        name: {
            required,
        },
        email: {
            required,
            email
        },
    },
    methods:{
        updateClient(){
			this.isLoaded = true;
            this.$axios.put(`${this.url}api/clients`, {
                name: this.name,
                email: this.email,
                nip: this.nip,
                type: this.isFirm ? 0 : 1,
                postcode: this.postcode,
                city: this.city,
                street: this.street,
                id: this.id,
                firstName: this.firstName,
                lastName: this.lastName,
                position: this.position,
			}, { headers: { 'x-access-token': token } })
				.then(()=>{
					this.popup = false;
					this.isLoaded = false;
				})
        }
    }
}
</script>
